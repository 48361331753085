import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const HeaderStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-header {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        padding-top: 12px;
        padding-bottom: 16px;
        background-color: #ff0037 !important;

        &.leshen-header-desktop {
          padding-bottom: 0;

          .subnav li:last-child,
          .subnav li:last-child a {
            border-bottom-right-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
          }

          .subnav {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          nav {
            flex-wrap: wrap;
          }

          .primary-link {
            &:first-of-type {
              margin-left: 0;
            }
          }

          .phone-link {
            align-self: flex-start;
            margin-bottom: 12px;
          }
        }

        .main-nav {
          background-color: #ff0037 !important;
        }

        /* Header Desktop CTA */
        .phone-link {
          font-weight: 700;
          font-size: 18px;
          line-height: 1;
          color: #ff0037 !important;
          transition: background-color 0.3s;
          background-color: #fff;
          padding: 12px 14px 8px;
          border-radius: 32px;

          &:hover {
            text-decoration: none;
            color: #fff !important;
            background-color: #141928;
          }

          &:active {
            color: #fff;
            background-color: #141928;
          }
        }

        img[alt='logo'] {
          display: block;
          width: 130px;
          height: 30px;
          margin-right: 30px;
        }

        .primary-link {
          color: #ffffff;
          align-self: flex-end;

          &:hover {
            background-color: #00001b !important;
            /* .subnav {
              top: 70px !important;
            } */
          }
        }
      }

      ul li button {
        &:active {
          background-color: unset !important;
          color: unset !important;
        }
      }

      .leshen-header-mobile {
        .menu-button svg path {
          fill: #fff;
        }
      }
    `}
  />
))

export default HeaderStyles
